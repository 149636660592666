<template>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css">
  <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
  <link href="css/bootstrap.css" rel="stylesheet">
  <link rel="icon" type="image/jpeg" href="./assets/pureleaf.jpeg" />
  
  <header>
    <NavbarPage/>  
  </header> 
  <transition  enter-active-class="fade-enter-active">
    <router-view class="view" style="height: 100%;"/>
  </transition>
  <FooterComponent />  
</template>

<script>
import NavbarPage from '@/NavbarPage.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
  name: 'App',
  components: { NavbarPage, FooterComponent },
}
</script>

<style>
html, body, #app {
  margin:0 !important;
  padding:0 !important;
  height:100vh !important;
  width:100vw !important;
  display: flex;
  flex-direction: column;
}

.fade-enter-active {
  animation: go 3s;
}

@keyframes go {
  from {
    background: #000000;
  }
  to {
    background: #ffffff;
  }
}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff; 
  border: solid 2px black;
}
</style>
