
<template>
  <li class="nav-item">
    <router-link :to="link[1]" class="nav-link text-white">
      <slot />
    </router-link>
  </li>
</template>
<script>
    export default {
      props: ["link"],
      created: function() {
        //console.log(this.link);
      },
    };
</script>