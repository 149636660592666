<template>
    <div id="home" class="root" style="height: 100%;">
        <div class="container d-flex flex-column text-center" style="height: 100%;justify-content: center; align-items: center;">
            <img class="rounded-circle" width="300" height="300" src="../assets/MePicture.png" alt="Image of creator" />
            <h1 class="display-4">Welcome to my portfolio</h1>
            <p>My name is Christopher-William I've been developping and manipulating electronics since I'm 12</p>
            <a href="#" class="btn btn-dark" @click="toggleCardAboutMe" style="background-color: lime;color: black;">More about me</a>
            <div v-if="showCard" class="rounded border border-info" style="position: absolute; height:80%; width: 90%; overflow-y:auto;display: flex;flex-direction:column;background-image: linear-gradient(rgb(60, 255, 0), rgb(182, 181, 181));">
                <div class="card-body" style="">
                    <h1 class="card-title">About me</h1>
                    <p class="card-text">I'm a 20 years old student in computer science. I've been developping and manipulating electronics since I'm 12. I'm passionate about technology and I'm always looking for new challenges.</p>
                   
                    <h2 class="card-title" style="text-align: start;"><strong>Studies</strong></h2>
                    <div style="display: flex;">
                        <img class="rounded" width="75" height="75" src="../assets/andre.png" alt="Cegep logo" />
                        <div style="text-align: start;display: flex;flex-direction: column; margin-left: 5px;">
                            <h3 class="card-text">Cégep André-Laurendeau</h3>
                            <ul style="list-style-type: disclosure-closed;">
                                <li>Computer science</li>
                                <li>Favorite position is <strong>BACK-END</strong></li>
                            </ul>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <img class="rounded" width="75" height="75" src="../assets/ets.jpg" alt="University logo" />
                        <div style="text-align: start;display: flex;flex-direction: column; margin-left: 5px;">
                            <h3 class="card-text">École de technologie supérieure</h3>
                            <ul style="list-style-type: disclosure-closed;">
                                <li>Bachelor of Software Engineering</li>
                                <li>Starting in <strong>Fall 2024</strong></li>
                            </ul>
                        </div>
                    </div>
                    <h2 class="card-title" style="text-align: start;"><strong>Works</strong></h2>
                    <div style="display: flex;">
                        <img class="rounded" width="75" height="75" src="../assets/cyframe.jpg" alt="Cegep logo" />
                        <div style="text-align: start;display: flex;flex-direction: column; margin-left: 5px;">
                            <h3 class="card-text">CyFrame ERP Solutions</h3>
                            <ul style="list-style-type: disclosure-closed;">
                                <li><strong>Started in </strong> January 2024</li>
                                <li><strong>Position</strong> : Full stack developper</li>
                                <li>Full reconception of the application Punch for factory purposes. Transformation from ASP Classic to ExpressJS with full UI redesign</li>
                            </ul>
                        </div>
                    </div>
                    <h2 class="card-title" style="text-align: start;"><strong>My skills</strong></h2>
                    <div style="display: flex;flex-direction: column;overflow-x: auto;">
                        <table class="table table-striped table-dark" >
                            <thead>
                                <tr>
                                    <th scope="col" >Programming</th>
                                    <th scope="col" >Language</th>
                                    <th scope="col" >Frameworks</th>
                                    <th scope="col" >Tools</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Object Oriented Programming</td>
                                    <td>HTML, JS & CSS</td>
                                    <td>Angular</td>
                                    <td>VSCode</td>
                                </tr>
                                <tr>
                                    <td>Refactoring and Code Smells</td>
                                    <td>Java</td>
                                    <td>React</td>
                                    <td>Git & GitHub</td>
                                </tr>
                                <tr>
                                    <td>Datastructures & Algorithms</td>
                                    <td>JavaScript & TypeScript</td>
                                    <td>Spring</td>
                                    <td>IntelliJ</td>
                                </tr>
                                <tr>
                                    <td>Unit & Mocking test</td>
                                    <td>C#</td>
                                    <td>VUEJS</td>
                                    <td>Jira</td>
                                </tr>
                                <tr>
                                    <td>Concurrency and Parallelism</td>
                                    <td>Python</td>
                                    <td>ExpressJS</td>
                                    <td>JUnit</td>
                                </tr>
                                <tr>
                                    <td>Database Management</td>
                                    <td>SQL</td>
                                    <td>Flutter</td>
                                    <td>Copilot</td>
                                </tr>
                            </tbody>
                        </table> 
                    </div>
                    <div style="display: flex;flex-direction: column;">
                        <h2 class="card-title" style="text-align: start;"><strong>My Ambitions</strong></h2>
                        <p class="card-text">I'm looking for a job in the field of computer science to improve my skills and to learn new ones. I'm also looking for a job that will allow me to work on projects that will have a positive impact on the world.</p>
                    </div>
                    <a href="#" class="btn btn-dark" @click="toggleCardAboutMe" style="background-color: black;color: lime;">Close about me</a>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeUnmount } from 'vue';

export default {
    name: 'HomeComponent',
    setup() {
        const showCard = ref(false);

        const toggleCardAboutMe = (event) => {
            showCard.value = !showCard.value;
            event.stopPropagation();

            if (showCard.value) {
                // Add event listener to detect clicks outside the card
                document.addEventListener('click', handleClickOutsideCard);
            } else {
                // Remove event listener when the card is hidden
                document.removeEventListener('click', handleClickOutsideCard);
            }
        };

        const handleClickOutsideCard = (event) => {
            // Close the card if the click is outside the card
            if (!event.target.closest('.border-info')) {
                showCard.value = false;
                document.removeEventListener('click', handleClickOutsideCard);
            }
        };

        onBeforeUnmount(() => {
            // Clean up the event listener if the component is destroyed
            document.removeEventListener('click', handleClickOutsideCard);
        });

        return {
            showCard,
            toggleCardAboutMe,
            handleClickOutsideCard,
        };
    },
};
</script>


<style>
.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>