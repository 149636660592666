<template>
    <Navbar />
</template>

<script>
    import Navbar from './components/Navbar.vue';
    export default {
        name: 'NavbarPage',
        components: {
            Navbar
        }
    }
</script>