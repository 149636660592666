<template>
  <div id="programmingLanguage" class="root text-center overflow-hidden">
    <div class="c1-container container">
      <div class="d-flex flex-column h-100">
        <h1 class="title">Programming Skills</h1>
        <div class="skillBarContainer">
          <div class="skill-bar" v-for="skill in skills" :key="skill.name">
            <div class="skill-header">
              <span class="skill-name">{{ skill.name }}</span>
              <span class="skill-percentage">{{ skill.percentage }}%</span>
            </div>
            <div class="progress-bar">
              <div class="fill" :style="{ width: skill.percentage + '%' }"></div>
            </div>
            <p class="skill-details">{{ skill.details }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgrammingLangPage',
  data() {
    return {
      skills: [
        {
          name: 'Java',
          percentage: 90,
          details: 'Proficient in Java and experienced in developing Java applications.'
        },
        {
          name: 'JavaScript',
          percentage: 90,
          details: 'Strong knowledge of JavaScript and its various libraries and frameworks.'
        },
        {
          name: 'Spring',
          percentage: 90,
          details: 'Experienced in developing web applications using the Spring framework.'
        },
        {
          name: 'React',
          percentage: 85,
          details: 'Proficient in building web applications using React.'
        },
        {
          name: 'Database',
          percentage: 85,
          details: 'Experienced in database design, SQL, and working with various database systems.'
        },
        {
          name: 'Python',
          percentage: 75,
          details: 'Skilled in Python programming and scripting for various applications.'
        },
        {
          name: 'Angular',
          percentage: 70,
          details: 'Familiar with Angular for front-end development and single-page applications.'
        },
        {
          name: 'Flutter and Dart',
          percentage: 70,
          details: 'Multi-platform app development using Flutter and Dart programming language. A few projects completed.'
        },
        {
          name: 'C#',
          percentage: 55,
          details: 'Basic knowledge of C# programming language and .NET framework.'
        },
      ]
    };
  }
};
</script>

<style scoped>

.c1-container {
  width: 80% !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #34495e;
  text-align: center;
  word-wrap: break-word;
}

.skillBarContainer {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
}

.skill-bar {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  box-sizing: border-box;
  padding: 15px;
}

.skill-bar:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.skill-header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.fill {
  height: 100%;
  background-color: #3498db;
  transition: width 0.4s ease;
}

.skill-details {
  font-size: 0.9rem;
  color: #7f8c8d;
  margin: 0;
  overflow-wrap: break-word;
}
</style>
