<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-dark">
    <router-link class="navbar-brand" to="/">
      <img class="rounded-circle img-fluid" id=home-profile height="50" width="50" alt="Image of creator" src="../assets/pureleaf.png" />
    </router-link>
    <button class="navbar-toggler" type="button" @click="toggleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" :class="{ show: isNavbarOpen }" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active"  id=home>
          <router-link @click="closeNavBar" class="nav-link text-white" to="/">Home <span class="sr-only">(current)</span></router-link>
        </li>
        <li class="nav-item" id=projects>
          <router-link @click="closeNavBar" to="/projects" class="nav-link text-white">My projects</router-link>
        </li>
        <li class="nav-item" id=minigame1>
          <router-link @click="closeNavBar"  to="/minigame1" class="nav-link text-white">Mini game 1</router-link>
        </li>
        <li class="nav-item" id=dropdown>
          <a class="">
            <dropdown title="More" ref="dropdownRef" class="custom-dropdown">
              <div class="container absolute" style="width: 170px;">
                <ul class="container bg-secondary border">
                  <dropdown-item id="custom-dropdown" @click="closeNavBar" v-for="item in dropdown_items" :key="item" :link="item">{{ item[0] }}</dropdown-item>
                </ul>
              </div>
            </dropdown>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import Dropdown from "./dropdown/DropDown.vue";
import DropdownItem from "./dropdown/DropdownItem.vue";

export default {
  data() {
    return {
      isNavbarOpen: false,
      dropdown_items: [["Programming languages", "/programmingLanguages"], ["My CV", "/myCV"], ["How to contact me", "/contact"]],
    };
  },
  methods: {
    toggleNavbar(event) {
      this.isNavbarOpen = !this.isNavbarOpen;
      event.stopPropagation();

      if (this.isNavbarOpen) {
        window.addEventListener("click", this.handleClickOutsideNavBar);
      } else {
        window.removeEventListener("click", this.handleClickOutsideNavBar); 
      }
    },
    handleClickOutsideNavBar(event) {
      var clickedInsideNavbar = event.target.closest(".navbar");
      var clickedInsideNavbarNav = event.target.closest(".nav-item");
      if (!clickedInsideNavbar || !clickedInsideNavbarNav) {
        this.closeNavBar();
      }
    },
    closeNavBar() {
      this.isNavbarOpen = false;
      window.removeEventListener("click", this.handleClickOutsideNavBar);
    },
  },
  name: 'NavbarPage',
  components: {
    Dropdown,
    DropdownItem,
  },
}
</script>
<style>
ul {
    list-style-type: none;
}

.custom-dropdown {
    position: relative;
}

.custom-dropdown .container {
    position: absolute;
    top: 100%; /* Position the dropdown below the anchor element */
    z-index: 1099; /* Set a high z-index to ensure it appears on top of everything */
    box-shadow: 10px 5px 5px rgb(0, 252, 0);
    border-radius: 10px;
}
</style>
