<template>
    <div class="root text-center">
      <h1>This is my CV</h1>
      <div class="iframeContainer">
        <iframe :src="cvDocumentLink" width="100%" height="600"></iframe>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: 'MyCvPage',
    data() {
      return {
        // Use your Google Docs shared link
        cvDocumentLink:
          'https://docs.google.com/document/d/1xsyFLMfuwq21CeOm60hNcFP893trvni0/edit?usp=sharing',
      };
    },
  };
</script>
  
<style>
.iframeContainer {
  width: 75%;
  margin: auto;
}
</style>
  