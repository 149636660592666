<template>
  <div style="display: flex; flex-direction: column; height: 100vh; width: 100%;">
    <div id="flappy" class="root container" style="flex: 1; display: flex; flex-direction: column; align-items: center;">
      <div class="text-center">
        <h1>Flappy Bird</h1>
        <p v-if="!gameStarted">Press Start Game or Tap or press Enter to start</p>
        <p v-if="gameStarted">Press Space or Tap to jump</p>
        <button class="btn btn-dark" v-if="!gameStarted" @click="startGame" style="background-color: lime;color: black;">Start Game</button>
        <button class="btn btn-dark" v-if="gameStarted" @click="jump" style="background-color: yellow;color: black;">Jump</button>
        <button class="btn btn-dark" v-if="!paused" @click="pauseGame" style="background-color: red;color: black;">Pause</button>
        <button class="btn btn-dark" v-if="paused" @click="resumeGame" style="background-color: lime;color: black;" >Resume Game</button>
      </div>
      <div
        class="flappy-bird-game"
        @keydown.enter="startGame"
        @mousedown.left="jump"
        @keydown.space="jump"
        @touchstart="jump"
        @mouseenter="focusGame"
        tabindex="0"
      >
        <div class="bird" :style="{ top: birdY + 'px', left: birdX + 'px' }"></div>
        <div class="pipes">
          <div
            v-for="(pipe, index) in pipes"
            :key="index"
            class="pipe"
            :style="{ height: pipe.height + 'px', left: pipe.left + 'px', top: pipe.top + 'px' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      birdY: window.innerWidth < 600 ? 100 : 200, // Initial bird position based on screen size
      birdVelocity: 0, // Bird's vertical velocity
      pipes: [], // Array to store pipe positions
      gameStarted: false, // Add a game started flag
      paused: false, // Add a paused flag
    };
  },
  methods: {
    startGame() {
      if (!this.gameStarted) {
        if (!this.paused)
        this.gameStarted = true;
        this.createPipe();
        this.gameInterval = setInterval(() => {
          this.moveBird();
          this.movePipes();
          this.checkCollision();
        }, 20);
        this.pipeInterval = setInterval(() => {
          this.createPipe();
        }, 1500);
      }
    },
    focusGame() {
      if (!this.gameStarted) {
        this.$el.focus();
      }
    },
    blurGame() {
      if (!this.gameStarted) {
        this.$el.blur();
        return false;
      }
      return true;
    },
    pauseGame() {
      clearInterval(this.gameInterval);
      clearInterval(this.pipeInterval);
      this.paused = true;
    },
    resumeGame() {
      this.gameInterval = setInterval(() => {
        this.moveBird();
        this.movePipes();
        this.checkCollision();
      }, 20);
      this.pipeInterval = setInterval(() => {
        this.createPipe();
      }, 1500);
      this.paused = false;
    },
    jump() {
      if (this.gameStarted) {
        this.birdVelocity = -6; // Adjust as needed
      }
    },
    moveBird() {
      this.birdY += this.birdVelocity;
      if (window.innerWidth < 600) {
        this.birdVelocity += 0.4; // Adjust gravity as needed
      }
      else {
        this.birdVelocity += 0.5; // Adjust gravity as needed
      }
      // Prevent bird from moving out of the top boundary
      if (this.birdY < 0) {
        this.birdY = 0;
        this.birdVelocity = 0;
      }
    },
    movePipes() {
      for (let i = 0; i < this.pipes.length; i++) {
        this.pipes[i].left -= 2; // Adjust pipe speed as needed
        if (this.pipes[i].left + (window.innerWidth < 600 ? 25 : 50) < 0) {
          // Remove off-screen pipes
          this.pipes.splice(i, 1);
          i--;
        }
      }
    },
    createPipe() {
      const gapHeight = 150;
      //const pipeWidth = window.innerWidth < 600 ? 25 : 50;
      const maxPipeHeight = (window.innerHeight * 0.6) - gapHeight;
      const topPipeHeight = Math.floor(Math.random() * (maxPipeHeight - 50)) + 50;
      const bottomPipeHeight = maxPipeHeight - topPipeHeight;

      const topPipe = {
        height: topPipeHeight,
        left: window.innerWidth < 600 ? window.innerWidth : 400,
        top: 0,
      };

      const bottomPipe = {
        height: bottomPipeHeight,
        left: window.innerWidth < 600 ? window.innerWidth : 400,
        top: topPipeHeight + gapHeight,
      };

      this.pipes.push(topPipe, bottomPipe);
    },
    checkCollision() {
      if (this.birdY > document.querySelector('.flappy-bird-game').clientHeight) {
        // Bird hits the ground
        alert('Game Over');
        clearInterval(this.gameInterval);
        clearInterval(this.pipeInterval);
        this.gameStarted = false;
        window.location.reload();
      }
      for (let i = 0; i < this.pipes.length; i++) {
        const pipe = this.pipes[i];
        const birdRight = this.birdX + (window.innerWidth < 600 ? 25 : 50);
        const birdBottom = this.birdY + (window.innerWidth < 600 ? 25 : 50);
        const pipeRight = pipe.left + (window.innerWidth < 600 ? 25 : 50);

        if (
          birdRight > pipe.left &&
          this.birdX < pipeRight &&
          this.birdY < pipe.top + pipe.height &&
          birdBottom > pipe.top
        ) {
          // Collision detected
          alert('Game Over');
          clearInterval(this.gameInterval);
          clearInterval(this.pipeInterval);
          this.gameStarted = false;
          window.location.reload();
        }
      }
    },
  },
  computed: {
    birdX() {
      return window.innerWidth < 600 ? 50 : 100; // Adjust bird's horizontal position as needed
    },
  },
  mounted() {
    if (this.blurGame()) {
      this.$el.focus();
    }
  },
};
</script>

<style scoped>
.flappy-bird-game {
  position: relative;
  width: 100%;
  height: 50vh; /* Take most of the viewport height */
  max-height: 800px;
  border: 1px solid #000;
  overflow: hidden;
  background-color: skyblue;
}

.bird {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: yellow;
}

.pipe {
  position: absolute;
  width: 50px;
  background-color: green;
}

@media screen and (max-width: 600px) {
  .flappy-bird-game {
    height: 50vh; /* Take most of the viewport height on mobile */
  }

  .bird {
    width: 25px;
    height: 25px;
  }

  .pipe {
    width: 25px;
  }
}
</style>
