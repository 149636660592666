<template>
    <footer class="bg-secondary">
        <div class="footer-copyright text-center py-3">© 2024 Copyright:
            <router-link to="/contact" style="color: lime !important;">Christopher-William</router-link>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'FooterComponent',
    }
</script>

<style>
</style>