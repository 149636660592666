<template>
  <div class="root text-center">
    <h1>Contact Me</h1>
    <form @submit.prevent="sendEmail" class="contact-form bg-dark" >
      <div class="form-group">
        <label for="email" class="text-white">Your Email:</label>
        <input class="form-control" type="email" id="email" v-model="senderEmail" required>
      </div>
      <div class="form-group" > 
        <label for="message" class="text-white">Message:</label>
        <textarea class="form-control  form-text" id="message" v-model="emailMessage" rows="4" cols="4" style="resize: both;max-width:-webkit-fill-available;max-height: 300px; min-width: -webkit-fill-available;" required></textarea>
      </div>
      <button type="submit" class="btn btn-dark" style="background-color: lime;color: black;">Send Email</button>
    </form>
    <h1 id="emailFeedback"></h1>
    <div class="links">
      <a href="https://github.com/PureLeafGreen">
        <img class="img-fluid" height="50" width="50" alt="Image of creator" src="../../assets/github.png"/>
      </a>
      <a href="https://www.instagram.com/lechrist666?igsh=MWd1Z3BwcWM0NHIydw==">
        <img class="img-fluid" height="50" width="50" alt="Image of creator" src="../../assets/Instagram.png"/>
      </a>
      <a href="https://www.linkedin.com/in/christopher-william-archambault-bouffard/">
        <img class="img-fluid" height="50" width="50" alt="Image of creator" src="../../assets/linkedin.png"/>
      </a>
      <a href="https://www.facebook.com/JesusChrisDeNazareth/">
        <img class="img-fluid" height="50" width="50" alt="Image of creator" src="../../assets/facebook.png"/>
      </a>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactPage',
  data() {
    return {
      senderEmail: '',
      emailMessage: '',
    };
  },
  methods: {
    sendEmail() {
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.senderEmail || !this.emailMessage) {
        this.emailErrorMessage('Please fill out all fields.');
        return;
      }
      if (!emailRegex.test(this.senderEmail)) {
        this.emailErrorMessage('Please enter a valid email address.');
        return;
      }
      const templateParams = {
        senderEmail: this.senderEmail,
        emailMessage: this.emailMessage,
      };

      emailjs.send('service_ikj6qlk', 'template_pcojkiv', templateParams, 'OZhcwc3SQlsH71WbZ')
        .then((response) => {
          console.log('Email sent:', response);
          this.emailFeedback(false);
          this.senderEmail = '';
          this.emailMessage = '';
        })
        .catch((error) => {
          console.error('Failed to send email:', error);
          this.emailFeedback(true);
        });
    },
    emailErrorMessage(emailErrorMessage) {
      document.getElementById('emailFeedback').style.display = 'block';
      document.getElementById('emailFeedback').style.color = 'red';
      document.getElementById('emailFeedback').innerHTML = emailErrorMessage;
      setInterval(() => {
        document.getElementById('emailFeedback').style.display = 'none';
      }, 5000);
    },
    emailFeedback(error) {
      if (error) {
        document.getElementById('emailFeedback').style.display = 'block';
        document.getElementById('emailFeedback').style.color = 'red';
        document.getElementById('emailFeedback').innerHTML = 'Failed to send email. Please try again later.';
      }
      else {
        document.getElementById('emailFeedback').style.display = 'block';
        document.getElementById('emailFeedback').style.color = 'green';
        document.getElementById('emailFeedback').innerHTML = 'Email sent successfully! I will get back to you as soon as possible.';
      }
      setInterval(() => {
        document.getElementById('emailFeedback').style.display = 'none';
      }, 5000);
    },
  },
};
</script>

<style>
.links {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.contact-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  background-color: #007BFF;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-form {
    max-width: 100%;
  }
}
</style>
