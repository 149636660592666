<template>
    <div class="root text-center">
        <div class="c1-container container main">
            <div class="head">
                <h1>My projects</h1>
                <div class="carousel-controls">
                    <a class="carousel-control-prev" @click="prevSlide" role="button">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" @click="nextSlide" role="button">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            <div id="projectCarousel" class="carousel slide c1-container container" >
                <div class="carousel-inner"  >
                    <div v-for="(project, index) in allProjects" :key="project.title" :class="['carousel-item', { active: index === activeIndex }]" >
                        <div class="card bg-dark text-white mx-2 align-middle" >
                            <div class="card-body" >
                                <h5 class="card-title">{{ project.title }}</h5>
                                <p class="card-text">{{ project.description }}</p>
                                <img v-if="project.image" :src="project.image" class="img-fluid carousel-img" alt="Project image">
                                <div class="d-flex justify-content-around pt-3">
                                    <button @click="goToProject(project.link)" class="carousel-btn btn btn-dark" style="background-color: lime;color: black;">Go to the project</button>
                                    <button v-if="project.websiteLink !== 'Not available yet'" @click="goToWebsite(project.websiteLink)" class="carousel-btn btn btn-dark" style="background-color: lime;color: black;">Go to the website</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
  name: 'ContactPage',
  data() {
    return {
        allProjects: [
            {
                title: 'McGill Hackathon 2023',
                description: 'I participated in the McGill Hackathon 2023 and joined a team of 3 to create a space simulation to showcase the Hoffman Transfer. We used React and p5.js to create the simulation.',
                link: 'https://github.com/PureLeafGreen/hackaton-mcgill-physics-2023',
                websiteLink: 'https://echoingekko00.github.io/mcgill-physics-hackathon-2023/',
                image: require('../../assets/mcgillProject.png'),
            },
            {
                title: 'Event Flow Planner',
                description: 'I created an event flow planner to help me plan my events. I used React, Spring & Spring Security to create a protected app.',
                link: 'https://github.com/echoingekko00/applicationTache',
                websiteLink: 'Not available yet',
            },
            {
                title: 'My Portfolio',
                description: 'I created my portfolio to showcase my projects. I used Vue.js, Bootstrap, and CSS to create the website.',
                link: 'https://github.com/echoingekko00/portefolioVUEJS',
                websiteLink: 'https://christopherwilliam.ca/',
                image: require('../../assets/portefolio.png'),
            },
            {
                title: 'Vocal Chat App',
                description: 'I created a vocal chat app to help me communicate with my friends. I used kotlin to create the app.',
                link: 'https://github.com/echoingekko00/projet3Veille',
                websiteLink: 'Not available yet',
            }
        ],
        activeIndex: 0,
    };
  },
  methods: {
    nextSlide() {
      this.activeIndex = (this.activeIndex + 1) % this.allProjects.length;
    },
    prevSlide() {
      this.activeIndex = (this.activeIndex - 1 + this.allProjects.length) % this.allProjects.length;
    },
    goToProject(link) {
      window.open(link, '_blank');
    },
    goToWebsite(link) {
      window.open(link, '_blank');
    }
  }
};
</script>

<style>
.main {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.c1-container {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow-y: auto;
}
.carousel {
    position: relative;
    overflow: auto;
    align-self: center;
    max-width: 90% !important;
}

.carousel-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    width: 100%;
    position: sticky
    
}

.carousel-control-prev, .carousel-control-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    position: relative !important;
    filter: invert(1);
}


.carousel-inner {
    display: flex;
    flex-wrap: nowrap;
}

.carousel-item {
    flex: 0 0 auto;
    transition: transform 0.6s ease;
}

.carousel-img {
    object-fit: cover;
    max-width: 15rem;
    max-height: 15rem;
}

.carousel-btn {
    max-width: 50%;
    border-radius: 0;
    text-wrap: auto !important;
}


</style>
