<template>
    <div class="root">
        <h1>404</h1>
        <p>Page not found</p>
    </div>
</template>

<script>
    export default {
        name: 'ErrorPage',
    }
</script>

<style>
</style>