<template>
  <div class="dropdown relative" @click="toggleDropdown">
    <div class="nav-link text-white" v-html="title"></div>
    <div class="rounded shadow bg-white" v-if="show">
      <slot />
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  props: ["title"],
  setup() {
    const show = ref(false);

    const toggleDropdown = (event) => {
      show.value = !show.value;
      if (show.value) {
        window.addEventListener("click", handleClickOutside);
      } else {
        window.removeEventListener("click", handleClickOutside);
      }
      event.stopPropagation(); // Prevent the event from propagating upwards to the window listener immediately
    };

    const handleClickOutside = (event) => {
      // Check if the click occurred outside the dropdown element
      const dropdown = event.target.closest(".dropdown");
      if (!dropdown) {
        closeCustomerDropDown();
      }
    };

    const closeCustomerDropDown = () => {
      show.value = false;
      window.removeEventListener("click", handleClickOutside);
    };

    // Clean up when the component is unmounted
    // onBeforeUnmount(() => {
    //   window.removeEventListener("click", handleClickOutside);
    // });

    // Expose the methods so they can be accessed from outside the component

    return {
      show,
      toggleDropdown,
      closeCustomerDropDown,
    };
  },
};
</script>
